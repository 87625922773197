/**
 * Utils para fechas
 */


/**
 * Toma un string del tipo "hh:mm" o "h:mm"
 * y devuelve los minutos y horas como int
 */
export function parseTime(timeStr) {
    const timePattern = /^([01]?\d|2[0-3]):([0-5]\d)$/;
    if (!timePattern.test(timeStr)) {
        throw new Error('Invalid time format. Expected format is HH:MM or H:MM.');
    }
    
    const [hoursStr, minutesStr] = timeStr.split(':');
    
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    
    if (hours < 0 || hours > 23) {
        throw new Error('Hours must be in the range 0 to 23.');
    }
    if (minutes < 0 || minutes > 59) {
        throw new Error('Minutes must be in the range 0 to 59.');
    }
    
    return { hours, minutes };
}